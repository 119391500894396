<template>
  <div class="training-question-block auto-evaluate">
    <p class="training-question-title">
      {{ $t('module1.part2.intro.autoEvaluate.title') }}
    </p>
    <p class="training-question-label">
      {{ $t('module1.part1.intro.autoEvaluate.question') }}
    </p>
    <div class="training-question-content">
      <div class="content-wrapper">
        <slot name="content"></slot>
        <p class="help" v-if="!isAnswered">{{ $t('training.global.autoEvaluateHelp') }}</p>
      </div>
    </div>
    <div class="result" v-if="isAnswered">
      <p class="bubble" v-if="showBubble">
        {{ isUserConfident ? $t('module1.part1.intro.autoEvaluate.bubbleConfident') : $t('module1.part1.intro.autoEvaluate.bubbleNotConfident') }}
      </p>
      <div class="help">
        {{ isUserConfident ? $t('module1.part1.intro.autoEvaluate.helpConfident') : $t('module1.part1.intro.autoEvaluate.helpNotConfident') }}
      </div>
      <app-button-layout v-if="isUserConfident" @click="$emit('go-to-activity')">
        {{ $t('training.global.goToActivity') }}
      </app-button-layout>
      <app-button-layout :color="isUserConfident ? 'alternate-secondary' : 'primary'" @click="$emit('go-to-lesson')">
        {{ $t('training.global.goToLesson') }}
      </app-button-layout>
    </div>
  </div>
</template>

<script>
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'AutoEvaluationBlock',
  components: { AppButtonLayout },
  emits: ['go-to-activity', 'go-to-lesson'],
  props: {
    isUserConfident: {
      type: Boolean,
      required: true
    },
    isAnswered: {
      type: Boolean,
      required: true
    },
    showBubble: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.training-question-content {
  .content-wrapper {
    .help {
      margin-top: $space-m;
    }
  }
}
.result {
  margin-top: $space-l;
  text-align: center;
  .help {
    margin: $space-sm 0;
  }
  .app-button-layout {
    & + .app-button-layout {
      margin-top: $space-s;
    }
    width: 100%;
  }
}
</style>
