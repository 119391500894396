<template>
  <step-intro-layout :objectives="objectives">
    <template v-slot:content>
      <auto-evaluation-block :is-answered="hasUserLevel"
        :is-user-confident="isUserConfident"
        :show-bubble="true"
        @go-to-activity="goToActivity"
        @go-to-lesson="goToNextStep"
      >
        <template v-slot:content>
          <div class="content-wrapper">
            <p class="annotation high-level" :class="{ inactive: !isUserConfident && hasUserLevel }">{{$t('module1.part1.intro.autoEvaluate.highLevel') }}</p>
            <div class="energy-label-wrapper" :class="{ active: hasUserLevel}">
              <div v-for="(label, labelIndex) in labels"
               :key="label"
               :style="{ width: getLabelWidth(labelIndex) }"
               class="energy-label"
               :class="{ [label]: true, active: userLabel === label }"
               @click="setUserLabel(label)"
              >
                {{ label }}
                <div class="arrow"></div>
              </div>
            </div>
            <p class="annotation low-level" :class="{ inactive: isUserConfident && hasUserLevel }">{{ $t('module1.part1.intro.autoEvaluate.lowLevel') }}</p>
          </div>
        </template>
      </auto-evaluation-block>
    </template>
  </step-intro-layout>
</template>

<script>
import StepIntroLayout from '@/components/training/StepIntroLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import { computed } from 'vue'
import { mapState } from 'vuex'
import { etiquettesEnergetiquesData } from '@/data/etiquettesEnergetiquesData'
import AutoEvaluationBlock from '@/components/training/AutoEvaluationBlock'

export default {
  name: 'Module1part1Intro',
  emits: ['next-step', 'step-finished'],
  components: { AutoEvaluationBlock, StepIntroLayout },
  setup (props, context) {
    const { currentPartDatas, setPartData, currentPart, currentModule, goToNextStep, goToActivity } = useTrainingUtils()
    const userLabel = computed({
      get () {
        return currentPartDatas.value?.userLabel
      },
      set (value) {
        setPartData({ key: 'userLabel', data: value })
      }
    })
    return { userLabel, currentPart, currentModule, goToNextStep, goToActivity }
  },
  data () {
    return {
      labels: etiquettesEnergetiquesData
    }
  },
  computed: {
    ...mapState('training', ['steps']),
    objectives () {
      return [
        this.$t('module1.part1.intro.objectif1'),
        this.$t('module1.part1.intro.objectif2')
      ]
    },
    hasUserLevel () {
      return !!this.userLabel
    },
    isUserConfident () {
      return this.userLabel <= 'c'
    }
  },
  methods: {
    getLabelWidth (labelIndex) {
      return (65 + labelIndex * 30) + 'px'
    },
    setUserLabel (label) {
      this.userLabel = label
    }
  }
}
</script>

<style lang="scss" scoped>
.auto-evaluate {
  .content-wrapper {
    padding: 0 $space-s;
    display: flex;
    flex-direction: column;
    .annotation {
      font-size: $fz-ml;
      font-family: $ff-text;
      font-weight: $fw-m;
      line-height: $ln-m;
      &.inactive {
        opacity: 0.2;
      }
      &.high-level {
        color: #52af55;
      }
      &.low-level {
        color: #d81d1f;
      }
    }
    .energy-label-wrapper {
      margin: $space-sm 0;
      .energy-label + .energy-label {
        margin-top: $space-xs;
      }
      .energy-label {
        transition: transform 0.2s ease-in-out;
        &.active {
          transform: translateX(-5px);
        }
      }
      .energy-label:not(.active):hover {
        transform: translateX(-5px);
      }
      &.active {
        .energy-label {
          opacity: 0.2;
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}

</style>
