<template>
  <div class="step-intro-layout">
    <div class="step-intro-wrapper">
      <div class="head">
        <slot name="head">
          <div class="title-wrapper">
            <p class="part-count">{{ $t('training.part') }} {{ partIndex + 1 }}</p>
            <p class="label">{{ currentPart.label }}</p>
          </div>
          <div class="objectives-wrapper">
            <p class="title">
              {{ $t('training.global.stepIntroTitle') }}
            </p>
            <ul class="objectives">
              <li v-for="(obj, objIndex) in objectives" :key="'obj-' + objIndex">
                <img :src="require('@/assets/icons/check-circled-secondary.svg')" :alt="$t('global.imageAlts.checkIcon')">
                {{ obj }}
              </li>
            </ul>
          </div>
        </slot>
      </div>
      <div class="content">
        <slot name="content">
          <div class="content-wrapper">
            <div class="button-wrapper">
              <app-button-layout @click="$emit('goToLesson')">
                {{ $t('training.global.goToLesson') }}
              </app-button-layout>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'StepIntroLayout',
  components: { AppButtonLayout },
  emits: ['goToLesson'],
  props: {
    objectives: {
      type: Array,
      required: true
    }
  },
  setup () {
    const { currentModule, currentPart, getPartIndex } = useTrainingUtils()
    const partIndex = getPartIndex(currentModule.value.id, currentPart.value.id)
    return { currentPart, partIndex }
  }
}
</script>

<style lang="scss" scoped>
.step-intro-layout {
  background-color: $c-secondary;
  padding-bottom: 24px;
  height: 100%;
  .step-intro-wrapper {
    display: grid;
    gap: $space-sm;
    max-width: $training-max-width-tablet;
    margin: 0 auto;
    padding: $space-xl $space-s $space-ml $space-s;
    .head {
      color: #fff;
      .part-count {
        padding: $space-s $space-m;
        border-radius: $radius-xl;
        display: inline-block;
        color: $c-secondary;
        background-color: #fff;
        font-size: $fz-xs;
        font-weight: $fw-l;
        text-transform: uppercase;
      }
      .title-wrapper {
        color: #fff;
        .label {
          margin-top: $space-sm;
          font-family: $ff-big;
          font-size: $fz-mobile-xxl;
          font-weight: $fw-l;
        }
      }
    }
    .objectives-wrapper {
      margin-top: $space-xl;
      background-color: #fff;
      border-radius: $radius-xl;
      padding: $space-ml $space-l;
      .title {
        color: $c-secondary;
        font-family: $ff-big;
        font-size: $fz-l;
        font-weight: $fw-l;
        margin-bottom: $space-m;
      }
      ul.objectives {
        padding: 0;
        margin: 0;
        li + li {
          margin-top: $space-sm;
        }
        li {
          color: $c-secondary;
          display: flex;
          align-items: flex-start;
          font-weight: $fw-m;
          img {
            margin-right: $space-s;
          }
        }
      }
    }
    .content {
      margin-top: $space-s;
      .content-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .button-wrapper {
          background-color: #fff;
          padding: $space-m;
          border-radius: $radius;
        }
        .app-button-layout {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .step-intro-layout {
    .step-intro-wrapper {
      max-width: $training-max-width;
      grid-template-columns: repeat(2, 1fr);
      .head {
        margin-top: $space-xl;
      }
    }
    .content {
      .head {
        .title-wrapper {
          .label {
            font-size: $fz-xxl;
          }
        }
      }
    }
  }
}
</style>
